/**
 * These enumerate the settings for different views in the app.
 * The settings are:
 * Title: The title phrase of the page
 * ViewToggle: "Toggle View" button visibility
 * Filter: "Filter" button visibility
 * Notifications: "Notifications" button visibility
 * Note that uiStates properties must match the string values in the views.js enum.
 */
export default {
  MyProfile: {
    title: 'editProfile',
    viewToggle: false,
    filter: false,
    notifications: false,
  },
  MyPosts: {
    title: 'myVisits',
    viewToggle: true,
    filter: true,
    notifications: true,
  },
  MyDistrict: {
    title: 'district',
    viewToggle: true,
    filter: false,
    notifications: true,
  },
  AllPosts: {
    title: 'allVisits',
    viewToggle: true,
    filter: true,
    notifications: true,
  },
  Bookmarks: {
    title: 'bookmarks',
    viewToggle: true,
    filter: false,
    notifications: true,
  },
  Customers: {
    title: 'customers',
    viewToggle: false,
    filter: false,
    notifications: false,
  },
  VisitPlansApp: {
    title: 'visitPlans',
    viewToggle: false,
    filter: false,
    notifications: true,
  },
  OwnVisitPlanVisits: {
    title: 'myVisits',
    viewToggle: true,
    filter: false,
    notifications: true,
  },
  AllVisitPlanVisits: {
    title: 'allVisits',
    viewToggle: true,
    filter: false,
    notifications: true,
  },
};
