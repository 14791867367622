<template>
  <div class='element materialShadow scrollList'
       :class="{ displaymode_smallcards: showSmallCards, displaymode_insta: showInsta }"
       :id='containerId'>
    <div v-if="isLoading"
         id='contentLoader'><i class='fa fa-cog fa-spin'></i>{{ x('loadingDots') }}</div>
    <progressive-list-with-columns :items="items"
                                   v-if="!isLoading && errorText ===''"
                                   :containerId="containerId"
                                   :columnWidth="columnWidth"
                                   :no-more-elements-text="emptyText">
      <template v-slot="itemData">
        <component :is="itemComponent" :model="itemData.item"/>
      </template>
    </progressive-list-with-columns>
    <div v-if="errorText !== ''">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { guid } from '@/classes/guid';
  import uiStates from '@/components/Mixins/uiStates';
  import translate from '../Mixins/Translate';
  import FeedItem from './FeedItem';
  import FeedItemInsta from './FeedItemInsta';
  import Views from '../../enums/views';
  import DisplayModes from '../../enums/display-modes';
  import Events from '../../enums/event-names';
  import ProgressiveListWithColumns from '../ProgressiveListWithColumns';

  export default {
    name: 'feed',
    mixins: [translate],
    components: {
      'feed-item': FeedItem,
      'feed-item-insta': FeedItemInsta,
      'progressive-list-with-columns': ProgressiveListWithColumns,
    },
    data() {
      return {
        containerId: guid(),
        isLoading: true,
        // This might be set to true during load.
        // In that case, perform load when access token arrives.
        accessTokenWasMissing: false,
        // If a load error happens, this will hold the text.
        errorText: '',
        uiStates,
      };
    },
    computed: {
      userRole() {
        if (this.user) {
          return this.user.userRole;
        }
        return null;
      },
      dataType() {
        if (this.$route.params.type) {
          return this.$route.params.type;
        }
        return '';
      },
      columnWidth() {
        switch (this.displayModes[this.currentPage]) {
          case DisplayModes.LargeCards: return 280;
          case DisplayModes.SmallCards: return 140;
          case DisplayModes.Insta: return 100;
          default: return 280;
        }
      },
      emptyText() {
        switch (this.$route.params.type) {
          case Views.MyPosts:
          case Views.OwnVisitPlanVisits:
            return 'ownVisitsEmpty';
          case Views.AllPosts:
          case Views.AllVisitPlanVisits:
            return 'allVisitsEmpty';
          case Views.MyDistrict:
            return 'regionVisitsEmpty';
          case Views.Bookmarks:
            return 'bookmarksEmpty';
          default:
            return null;
        }
      },
      showSmallCards() {
        return this.displayModes[this.currentPage] === DisplayModes.SmallCards;
      },
      showInsta() {
        return this.displayModes[this.currentPage] === DisplayModes.Insta;
      },
      itemComponent() {
        // noinspection JSRedundantSwitchStatement
        switch (this.displayModes[this.currentPage]) {
          case DisplayModes.Insta: return 'feed-item-insta';
          default: return 'feed-item';
        }
      },
      ...mapState(['user', 'accessToken', 'displayModes', 'currentPage', 'filter']),
      ...mapState('FeedStore', ['items']),
    },
    watch: {
      async accessToken(newValue) {
        if (newValue === '') return;
        if (this.accessTokenWasMissing === true) {
          this.accessTokenWasMissing = false;
          await this.loadData(this.$route.params.type);
        }
      },
      userRole(newValue) {
        if (newValue) {
          this.loadData(this.$route.params.type);
        }
      },
      async dataType(newValue) {
        await this.loadData(newValue);
      },
      async filter() {
        await this.loadData(this.$route.params.type);
        // const newData = await httpPost('visits/filter', this.filters);
      },
    },
    mounted() {
      if (this.user === null) return;
      this.loadData(this.$route.params.type);
      this.registerEventHandlers();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.visitLiked, this.onItemLiked);
        this.$bus.on(Events.visitUnliked, this.onItemUnliked);
        this.$bus.on(Events.visitCommentAdded, this.onItemCommentAdded);
        this.$bus.on(Events.visitCommentRemoved, this.onItemCommentRemoved);
        this.$bus.on(Events.customerUpdated, this.onCustomerUpdated);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.visitLiked, this.onItemLiked);
        this.$bus.off(Events.visitUnliked, this.onItemUnliked);
        this.$bus.off(Events.visitCommentAdded, this.onItemCommentAdded);
        this.$bus.off(Events.visitCommentRemoved, this.onItemCommentRemoved);
        this.$bus.off(Events.customerUpdated, this.onCustomerUpdated);
      },
      // TODO: SHOULD THESE SOMEHOW BE DETACHED FROM THE COMPONENT?
      // THEY ARE ROUTED FROM THE SERVER THROUGH THE FEED TO THE FEED STORE, BUT
      // MAYBE THAT IS NOT NECESSARY.
      // ON THE OTHER HAND, THE FEED STORE SHOULD NOT BE ACTIVE UNLESS THE FEED IS.
      // ON THE THIRD HAND, HANDLING THE ITEMS OUTSIDE THE FEED COULD SPEED UP THE
      // PROCESS OF LOADING THE FEED, AND RELOADING IT WHEN THE CURRENT PAGE CHANGES.
      onItemCommentAdded(event) {
        this.$store.dispatch('FeedStore/setItemComment', event.visitId);
      },
      onItemCommentRemoved(event) {
        this.$store.dispatch('FeedStore/removeItemComment', event.visitId);
      },
      onItemLiked(event) {
        this.$store.dispatch('FeedStore/setItemLiked', { visitId: event.visit.id, userId: event.userId });
      },
      onItemUnliked(event) {
        this.$store.dispatch('FeedStore/unsetItemLiked', { visitId: event.visitId, userId: event.userId });
      },
      onCustomerUpdated(customer) {
        console.log('onCustomerUpdated:', customer);
        this.$store.dispatch('FeedStore/updateCustomer', customer);
      },
      async loadData(type) {
        if (this.$store.state.accessToken === '') {
          this.accessTokenWasMissing = true;
          return;
        }

        this.errorText = '';
        this.isLoading = true;

        try {
          if (this.filter === null || !this.uiStates[type].filter) {
            await this.$store.dispatch('FeedStore/loadFeed', type);
          } else {
            const filter = { ...this.filter }; // clone
            if (this.dataType === Views.MyPosts) {
              filter.users = [this.user.id];
            }
            await this.$store.dispatch('FeedStore/loadFeedFiltered', { filter, type });
          }
        } catch (e) {
          console.log(e);
          this.errorText = e.error.message;
          console.error(this.errorText);
          this.isLoading = false;
          return;
        }

        console.log(`Retrieved ${this.items.length} items.`);
        const itemIds = this.items.map((v) => v.id);
        this.$store.commit('setFeedItemIds', itemIds);
        // await this.$nextTick();
        this.isLoading = false;
      },
    },
  };
</script>
